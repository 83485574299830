import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import supabase from './supabaseClient';
import { useAuth } from './AuthContext';

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const { user } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [activeTaskId, setActiveTaskId] = useState(null);
  const [totalDaysCompleted, setTotalDaysCompleted] = useState(0);
  const [taskListResetTime, setTaskListResetTime] = useState({});
  const [summaryPersistentTime, setSummaryPersistentTime] = useState({});
  const [dailyProgress, setDailyProgress] = useState([]);
  const [ongoingProgress, setOngoingProgress] = useState(0);
  const [goalCompletions, setGoalCompletions] = useState({});
  const [completionDates, setCompletionDates] = useState({});
  const timerRef = useRef(null);

  useEffect(() => {
    if (user) {
      resetState();
      fetchUserData(user.id);
    }
  }, [user]);

  const resetState = () => {
    setTasks([]);
    setActiveTaskId(null);
    setTotalDaysCompleted(0);
    setTaskListResetTime({});
    setSummaryPersistentTime({});
    setDailyProgress([]);
    setOngoingProgress(0);
    setGoalCompletions({});
    setCompletionDates({});
  };

  const fetchUserData = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('user_progress')
        .select('total_days_completed, daily_progress, ongoing_progress, goal_completions')
        .eq('user_id', userId)
        .single();

      if (error) {
        console.error('Error fetching user data:', error);
        return;
      }

      if (data) {
        setTotalDaysCompleted(data.total_days_completed || 0);
        setDailyProgress(data.daily_progress || []);
        setOngoingProgress(data.ongoing_progress || 0);
        setGoalCompletions(data.goal_completions || {});
      }
    } catch (err) {
      console.error('Error fetching user data:', err);
    }
  };

const fetchTaskss = async () => {
  if (!user) return;

  const { data: fetchedTasks, error } = await supabase
    .from('tasks')
    .select('*')
    .eq('user_id', user.id)
    .order('id', { ascending: true });

  if (error) {
    console.error('Error fetching tasks:', error);
    return;
  }

  const updatedTasks = fetchedTasks.map((task) => {
    let newUpdatedElapsedTime = task.elapsed_time || 0;
    if (task.start_time) {
      const startTime = new Date(task.start_time).getTime();
      const now = Date.now();
      const diffInSeconds = Math.floor((now - startTime) / 1000);
      newUpdatedElapsedTime += diffInSeconds;
    }

    const isPending = task.start_time !== null;
    let progress = 0;
    let isComplete = false;
    let progressColorScheme = 'purple';

    if (task.status === 'GOAL') {
      progress = task.duration > 0 ? (newUpdatedElapsedTime / (task.duration * 60)) * 100 : 0;
      isComplete = progress >= 100;
      progressColorScheme = 'green';
    } else if (task.status === 'LIMIT') {
      progress = task.duration > 0 ? (newUpdatedElapsedTime / (task.duration * 60)) * 100 : 0;
      isComplete = progress >= 100;
      progressColorScheme = 'red';
    } else if (task.status === 'COUNT') {
      // Use count_value for COUNT tasks
      const countValue = task.count_value || 0;
      const fraction = task.duration > 0 ? countValue / task.duration : 0;
      progress = Math.min(fraction * 100, 100);
      isComplete = fraction >= 1;
      progressColorScheme = 'blue';
    } else {
      progress = task.duration > 0 ? (newUpdatedElapsedTime / (task.duration * 60)) * 100 : 0;
      isComplete = progress >= 100;
    }

    return {
      id: task.id,
      title: task.task_name,
      duration: task.duration,
      status: task.status,
      elapsed_time: newUpdatedElapsedTime,
      time: new Date(newUpdatedElapsedTime * 1000).toISOString().substr(11, 8),
      persistent_time: task.persistent_time || 0,
      isActive: activeTaskId === task.id,
      isPending,
      isComplete,
      progressColorScheme,
      progress,
      icon: task.icon || 'FaSun',
      iconBgColor: task.status === 'GOAL'
        ? 'green.500'
        : task.status === 'LIMIT'
          ? 'red.500'
          : 'blue.500',
      count_value: task.count_value || 0,
      persistent_count: task.persistent_count || 0
    };
  });

  setTasks(updatedTasks);
};


  const startTask = async (taskId) => {
    if (activeTaskId !== null && activeTaskId !== taskId) {
      stopTask();
    }

    const task = tasks.find(t => t.id === taskId);
    let initialTimeInSeconds = task ? task.elapsed_time || 0 : 0;

    await supabase
      .from('tasks')
      .update({ start_time: null })
      .eq('id', taskId);

    setActiveTaskId(taskId);
    localStorage.setItem('task_id', taskId);

    setTaskListResetTime(prevTimes => ({
      ...prevTimes,
      [task.id]: new Date(initialTimeInSeconds * 1000).toISOString().substr(11, 8)
    }));

    setTasks(prevTasks =>
      prevTasks.map(t => t.id === taskId ? { ...t, isActive: true, time: new Date(initialTimeInSeconds * 1000).toISOString().substr(11, 8) } : t)
    );

    timerRef.current = setInterval(() => {
      initialTimeInSeconds += 1;
      const newTimeString = new Date(initialTimeInSeconds * 1000).toISOString().substr(11, 8);
      setTasks(prevTasks =>
        prevTasks.map(t => t.id === taskId ? { ...t, time: newTimeString } : t)
      );
    }, 1000);
  };

  const stopTask = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    const task = tasks.find(task => task.id === activeTaskId);
    if (task) {
      const parts = task.time.split(':').map(Number);
      const totalSeconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
      updateElapsedTimeInDatabase(activeTaskId, totalSeconds);
      setTasks(prevTasks =>
        prevTasks.map(t => t.id === activeTaskId ? { ...t, elapsed_time: totalSeconds, isActive: false, isPending: false } : t)
      );
      setActiveTaskId(null);
    }
  };

  const upgradedStopTask = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    const task = tasks.find(task => task.id === activeTaskId);
    if (task) {
      const parts = task.time.split(':').map(Number);
      const totalSeconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
      updateElapsedTimeInDatabase(activeTaskId, totalSeconds);
      setTasks(prevTasks =>
        prevTasks.map(t => t.id === activeTaskId ? { ...t, elapsed_time: totalSeconds, isActive: false, isPending: false } : t)
      );
      setActiveTaskId(null);
      localStorage.removeItem('task_id');
    }
  };

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.hidden) {
        const taskId = localStorage.getItem('task_id');
        if (taskId) {
          const { data: currentTask, error } = await supabase
            .from('tasks')
            .select('start_time')
            .eq('id', taskId)
            .single();

          if (error) {
            console.error('Error fetching task:', error);
            return;
          }

          if (currentTask && !currentTask.start_time) {
            const timestamp = new Date().toISOString();
            const { error: updateError } = await supabase
              .from('tasks')
              .update({ start_time: timestamp })
              .eq('id', taskId);
            if (updateError) {
              console.error('Error updating start_time:', updateError);
              return;
            }
          }
          stopTask();
        }
      } else {
        const taskId = localStorage.getItem('task_id');
        if (taskId) {
          await fetchTaskss();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [stopTask, startTask, activeTaskId]);

  const updateElapsedTimeInDatabase = async (taskId, elapsedTime) => {
    try {
      const { error } = await supabase
        .from('tasks')
        .update({ elapsed_time: elapsedTime })
        .eq('id', taskId);
      if (error) {
        console.error('Error updating elapsed time:', error);
      } else {
        console.log(`Elapsed time for task ID ${taskId} updated to ${elapsedTime} seconds`);
      }
    } catch (err) {
      console.error('Error in updateElapsedTimeInDatabase:', err);
    }
  };

  useEffect(() => {
    if (activeTaskId !== null) {
      timerRef.current = setInterval(() => {
        setTasks(prevTasks =>
          prevTasks.map(task => {
            if (task.id === activeTaskId) {
              const timeStr = taskListResetTime[task.id] || "00:00:00";
              const [h = 0, m = 0, s = 0] = timeStr.split(':').map(Number);
              const totalSeconds = h * 3600 + m * 60 + s + 1;
              if (!isNaN(totalSeconds)) {
                const newTimeString = new Date(totalSeconds * 1000).toISOString().substr(11, 8);
                setTaskListResetTime(prevTimes => ({
                  ...prevTimes,
                  [task.id]: newTimeString
                }));
                const persistStr = summaryPersistentTime[task.id] || "00:00:00";
                const [persistH = 0, persistM = 0, persistS = 0] = persistStr.split(':').map(Number);
                const totalPersistSeconds = persistH * 3600 + persistM * 60 + persistS + 1;
                if (!isNaN(totalPersistSeconds)) {
                  const newPersistTimeString = new Date(totalPersistSeconds * 1000).toISOString().substr(11, 8);
                  setSummaryPersistentTime(prevTimes => ({
                    ...prevTimes,
                    [task.id]: newPersistTimeString
                  }));
                }
                updateTimeInDatabase(task.id, 1);
                return { ...task, time: newTimeString };
              }
            }
            return task;
          })
        );

        const relevantTasks = tasks.filter(t => t.status === 'GOAL' || t.status === 'COUNT');
        if (relevantTasks.length > 0) {
          let totalFraction = 0;
          for (let i = 0; i < relevantTasks.length; i++) {
            const t = relevantTasks[i];
            if (t.status === 'GOAL') {
              const fraction = t.duration > 0 ? Math.min(t.elapsed_time / (t.duration * 60), 1) : 0;
              totalFraction += fraction;
            } else if (t.status === 'COUNT') {
              const fracCount = t.duration > 0 ? Math.min(t.count_value / t.duration, 1) : 0;
              totalFraction += fracCount;
            }
          }
          const overallProgress = (totalFraction / relevantTasks.length) * 100;
          setOngoingProgress(overallProgress);
        } else {
          setOngoingProgress(0);
        }
      }, 1000);
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    return () => {
      clearInterval(timerRef.current);
    };
  }, [activeTaskId, tasks]);

  const updateTimeInDatabase = async (taskId, seconds) => {
    try {
      const { data, error } = await supabase
        .from('tasks')
        .select('elapsed_time')
        .eq('id', taskId)
        .single();
      if (error) {
        console.error('Error fetching task time:', error);
        return;
      }
      const currentElapsedTime = data ? data.elapsed_time || 0 : 0;
      const newElapsedTime = currentElapsedTime + seconds;
      const { error: updateError } = await supabase
        .from('tasks')
        .update({ elapsed_time: newElapsedTime })
        .eq('id', taskId);
      if (updateError) console.error('Error updating task time:', updateError);
    } catch (err) {
      console.error('Error in updateTimeInDatabase:', err);
    }
  };

  // UPDATED completeDay function:
  // It now accepts a parameter (finalProgress) from TaskListScreen
  // and uses that as the final daily progress value.
  const completeDay = async (finalProgress) => {
    // Wait a bit longer for the final elapsed timer updates (adjust as needed)
    stopTask();
    await new Promise(resolve => setTimeout(resolve, 500));
    if (activeTaskId !== null) {
      setActiveTaskId(null);
    }

    // Reset any active tasks
    setTasks(prevTasks =>
      prevTasks.map(task =>
        task.start_time
          ? { ...task, start_time: null, elapsed_time: 0, isActive: false }
          : task
      )
    );

    // Update tasks persistent_time and reset counts
    await Promise.all(tasks.map(async task => {
      const elapsedTime = task.elapsed_time || 0;
      const persistentTime = task.persistent_time || 0;
      const newPersistentTime = elapsedTime + persistentTime;
      if (task.status === 'GOAL' && elapsedTime >= task.duration * 60) {
        // Increase goal completions for this task
        const currentGoalCompletion = goalCompletions[task.id] || 0;
        goalCompletions[task.id] = currentGoalCompletion + 1;
      }
      const updates = { elapsed_time: 0, persistent_time: newPersistentTime };
      if (task.status === 'COUNT') {
        updates.count_value = 0;
        updates.persistent_count = (task.persistent_count || 0) + (task.count_value || 0);
      }
      const { error: updateError } = await supabase
        .from('tasks')
        .update(updates)
        .eq('id', task.id);
      if (updateError) {
        console.error('Error updating tasks on complete day:', updateError);
      }
    }));

    // Use the finalProgress value passed in (from TaskListScreen)
    const currentProgress = finalProgress;

    const newDailyProgress = dailyProgress.slice();
    newDailyProgress.push(currentProgress);
    const newTotalDaysCompleted = totalDaysCompleted + 1;
    const newGoalCompletions = { ...goalCompletions };

    if (user && user.id) {
      const { error: userProgressError } = await supabase
        .from('user_progress')
        .upsert({
          user_id: user.id,
          total_days_completed: newTotalDaysCompleted,
          daily_progress: newDailyProgress,
          ongoing_progress: 0,
          goal_completions: newGoalCompletions
        });
      if (userProgressError) {
        console.error('Error saving user progress data:', userProgressError);
      } else {
        console.log("User progress successfully saved.");
      }
    } else {
      console.error("User ID is null or undefined, skipping progress save.");
    }

    setDailyProgress(newDailyProgress);
    setTotalDaysCompleted(newTotalDaysCompleted);
    setGoalCompletions(newGoalCompletions);
    setCompletionDates(completionDates + 1);

    setTaskListResetTime({});
    setTasks(prevTasks =>
      prevTasks.map(task => ({
        ...task,
        time: '00:00:00',
        isActive: false,
        elapsed_time: 0
      }))
    );

    setActiveTaskId(null);
    setOngoingProgress(0);
    clearInterval(timerRef.current);
  };

  return (
    <TimerContext.Provider value={{
      tasks,
      setTasks,
      activeTaskId,
      startTask,
      stopTask,
      totalDaysCompleted,
      taskListResetTime,
      summaryPersistentTime,
      completeDay,
      dailyProgress,
      ongoingProgress,
      goalCompletions,
      completionDates,
      setCompletionDates,
      setTotalDaysCompleted,
      setDailyProgress,
      setOngoingProgress,
      setActiveTaskId,
      updateElapsedTimeInDatabase,
      setGoalCompletions,
      fetchTaskss,
      upgradedStopTask
    }}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => useContext(TimerContext);

