import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Text,
  HStack,
  Divider,
  Progress,
  CircularProgress,
  CircularProgressLabel
} from '@chakra-ui/react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import BottomNavBar from './BottomNavBar';
import { useTimer } from '../TimerContext';
import supabase from '../supabaseClient';
import { useAuth } from '../AuthContext';
import { useLocation } from 'react-router-dom';

const SummaryScreen = () => {
  const {
    tasks,
    totalDaysCompleted,
    setTotalDaysCompleted,
    dailyProgress,
    setDailyProgress,
    ongoingProgress,
    setOngoingProgress,
    goalCompletions,
    setGoalCompletions
  } = useTimer();

  const { user } = useAuth();
  const location = useLocation();
  const [dayOffset, setDayOffset] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);

  const fetchProgressData = async () => {
    if (!user || !user.id) {
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase
        .from('user_progress')
        .select('total_days_completed, daily_progress, ongoing_progress, goal_completions, created_at')
        .eq('user_id', user.id)
        .single();

      if (error) {
        console.error('Error fetching progress data:', error);
        setLoading(false);
        return;
      }

      if (data) {
        if (data.total_days_completed !== undefined && data.total_days_completed !== null) {
          setTotalDaysCompleted(data.total_days_completed);
        }

        if (Array.isArray(data.daily_progress)) {
          setDailyProgress(data.daily_progress);
        }

        if (typeof data.ongoing_progress === 'number') {
          setOngoingProgress(data.ongoing_progress);
        }

        if (data.goal_completions && typeof data.goal_completions === 'object') {
          setGoalCompletions(data.goal_completions);
        }

        if (data.created_at) {
          setStartDate(new Date(data.created_at));
        }


        const nextDay = (data.total_days_completed !== undefined && data.total_days_completed !== null)
          ? data.total_days_completed + 1
          : totalDaysCompleted + 1;
        const initialOffset = Math.floor((nextDay - 1) / 4) * 4;
        setDayOffset(initialOffset);
      } else {
        console.log('No user progress data found.');
      }
    } catch (err) {
      console.error('Error in fetchProgressData:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProgressData();
  }, [user, location]);

  useEffect(() => {
    let totalTrackedTime = 0;
    for (let i = 0; i < tasks.length; i++) {
      const t = tasks[i];
      const totalPersistentTime = t.persistent_time || 0;
      totalTrackedTime += totalPersistentTime;
    }
    setTotalTime(totalTrackedTime);
  }, [tasks]);

  const calculateTaskPercentage = (task) => {
    if (task.status === 'GOAL') {
      if (totalTime === 0) return 0;
      const taskPersistentTime = task.persistent_time || 0;
      return (taskPersistentTime / totalTime) * 100;
    } else if (task.status === 'COUNT') {
      if (task.duration > 0 && totalDaysCompleted > 0) {
        let fraction = task.persistent_count / (task.duration * totalDaysCompleted);
        if (fraction > 1) fraction = 1;
        return fraction * 100;
      } else {
        return 0;
      }
    } else {
      if (totalTime === 0) return 0;
      const pt = task.persistent_time || 0;
      return (pt / totalTime) * 100;
    }
  };


  const handleNextDays = () => {
    const goldDay = totalDaysCompleted + 1;
    const maxOffset = Math.floor((goldDay - 1) / 4) * 4;
    if (dayOffset < maxOffset) {
      setDayOffset(dayOffset + 4);
    }
  };

  const handlePrevDays = () => {
    if (dayOffset > 0) {
      setDayOffset(dayOffset - 4);
    }
  };

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return hrs + "h " + mins + "m " + secs + "s";
  };

  const formatDate = (baseDate, dayNumber) => {
    if (!baseDate) {
      return "Day " + dayNumber;
    }
    const date = new Date(baseDate.getTime());
    date.setDate(date.getDate() + (dayNumber - 1));
    let mm = String(date.getMonth() + 1);
    if (mm.length < 2) mm = '0' + mm;
    let dd = String(date.getDate());
    if (dd.length < 2) dd = '0' + dd;
    const yy = String(date.getFullYear()).slice(-2);
    return mm + "/" + dd + "/" + yy;
  };

  return (
    <Box
      bgGradient="linear(to-r, black, purple.900)"
      color="white"
      minH="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      p={6}
      pb={20}
    >
      {loading ? (
        <CircularProgress isIndeterminate color="green.300" />
      ) : (
        <VStack spacing={6} w="100%" maxW="md">
          <HStack w="100%" justifyContent="space-between">
            <VStack>
              <Text fontSize="xl" fontWeight="bold" color="green.500">
                Total Days Completed
              </Text>
              <Text fontSize="2xl">{totalDaysCompleted}</Text>
            </VStack>
            <VStack>
              <Text fontSize="xl" fontWeight="bold" color="blue.500">
                Total Time Duration
              </Text>
              <Text fontSize="2xl">
                {Math.floor(totalTime / 3600)} hrs {Math.floor((totalTime % 3600) / 60)} min
              </Text>
            </VStack>
          </HStack>
          <Divider />
          <HStack spacing={4} justifyContent="center" w="100%">
            {dayOffset > 0 && (
              <button onClick={handlePrevDays}>
                <FaArrowLeft />
              </button>
            )}
            {Array.from({ length: 4 }).map((_, index) => {
              const dayNumber = index + 1 + dayOffset;
              const dayProgress = dailyProgress[dayNumber - 1] || 0;
              const displayDate = formatDate(startDate, dayNumber);

              return (
                <VStack key={index} spacing={1}>
                  <Text fontSize="sm" color="gray.300">{displayDate}</Text>
                  <CircularProgress
                    value={dayProgress}
                    color="green.400"
                    size="40px"
                    thickness="12px"
                  >
                    <CircularProgressLabel color={dayNumber === totalDaysCompleted + 1 ? "gold" : "inherit"}>
                      {dayNumber}
                    </CircularProgressLabel>
                  </CircularProgress>
                </VStack>
              );
            })}
            {/* Render the right arrow if the next (gold) day is not within the current block */}
            {(totalDaysCompleted + 1 > dayOffset + 4) && (
              <button onClick={handleNextDays}>
                <FaArrowRight />
              </button>
            )}
          </HStack>
          <Divider />
          <VStack w="100%" spacing={4}>
            {tasks
              .slice()
              .sort((a, b) => {
                if (a.status === 'GOAL' && b.status !== 'GOAL') return -1;
                if (a.status !== 'GOAL' && b.status === 'GOAL') return 1;
                return 0;
              })
              .map((task) => {
                if (task.status === 'COUNT') {
                  return (
                    <Box key={task.id} w="100%" mb={4}>
                      <Box display="grid" style={{ gridTemplateColumns: '50% 50%' }} w="100%" alignItems="center">
                        <Text color="blue.500">{task.title}</Text>
                        <Text textAlign="right" style={{ justifySelf: 'right' }}>
                          Count: {task.persistent_count}
                        </Text>
                      </Box>
                      <Progress
                        value={calculateTaskPercentage(task)}
                        size="lg"
                        colorScheme="blue"
                      />
                    </Box>
                  );
                } else {
                  return (
                    <Box key={task.id} w="100%" mb={4}>
                      <Box display="grid" style={{ gridTemplateColumns: '25% 25% 50%' }} w="100%" alignItems="center">
                        <Text color="purple.500">{task.title}</Text>
                        <Text textAlign="center" style={{ justifySelf: 'center' }}>
                          {(goalCompletions[task.id] || 0)} days
                        </Text>
                        <Text textAlign="right" style={{ justifySelf: 'right' }}>
                          {calculateTaskPercentage(task).toFixed(2)}% ({formatTime(task.persistent_time || 0)})
                        </Text>
                      </Box>
                      <Progress
                        value={calculateTaskPercentage(task)}
                        size="lg"
                        colorScheme={task.status === 'GOAL' ? 'green' : 'red'}
                      />
                    </Box>
                  );
                }
              })}
          </VStack>
        </VStack>
      )}
      <BottomNavBar />
    </Box>
  );
};

export default SummaryScreen;

